export default {
  notfound: "/assets/images/404.svg",
  testicon: "/assets/icons/2082.png",
  testicon1: "/assets/icons/icon-192x192.png",
  testicon2: "/assets/icons/icon-256x256.png",
  testicon3: "/assets/icons/icon-384x384.png",
  testicon4: "/assets/icons/icon-512x512.png",
  image: "/assets/icons/auricular-phone_318-1028.webp",
  roundchart: "/assets/images/roundchart.png",
  adminIcon: "/assets/images/adminIcon.svg",
  avatar: "/assets/images/avatar.png",
  clientIcon: "/assets/images/clientIcon.svg",
  dashboardIcon: "/assets/images/dashboardIcon.svg",
  downArrow: "/assets/images/downArrow.svg",
  drpdwnarrw: "/assets/images/drpdwn-arrw.svg",
  eyeClose: "/assets/images/eyeClose.svg",
  eyeOpen: "/assets/images/eyeOpen.svg",
  graphChart: "/assets/images/graphChart.svg",
  lockRedIcon: "/assets/images/lockRedIcon.svg",
  loginLogo: "/assets/images/loginLogo.png",
  logo: "/assets/images/logo.png",
  mailGrayIcon: "/assets/images/mailGrayIcon.svg",
  mailRedIcon: "/assets/images/mailRedIcon.svg",
  manageIcon: "/assets/images/manageIcon.svg",
  managementIcon: "/assets/images/managementIcon.svg",
  newsIcon: "/assets/images/newsIcon.svg",
  Notificationimg: "/assets/images/notification-img.svg",
  notificationGrayIcon: "/assets/images/notificationGrayIcon.svg",
  partnerIcon: "/assets/images/partnerIcon.svg",
  patternBG: "/assets/images/patternBG.png",
  projectIcon: "/assets/images/projectIcon.svg",
  rightWhiteIcon: "/assets/images/rightWhiteIcon.svg",
  searchGray: "/assets/images/searchGray.svg",
  serchRedIcon: "/assets/images/serchRedIcon.svg",
  serviceIcon: "/assets/images/serviceIcon.svg",
  serviceproviderIcon: "/assets/images/serviceproviderIcon.svg",
  settingGrayIcon: "/assets/images/settingGrayIcon.svg",
  staffIcon: "/assets/images/staffIcon.svg",
  supportIcon: "/assets/images/supportIcon.svg",
  tableProfile: "/assets/images/tableProfile.png",
  toggleicon: "/assets/images/toggle-icon.svg",
  tickicon: "/assets/images/tickIcon.svg",
  totalClientIcon: "/assets/images/totalClientIcon.svg",
  totalEarningIcon: "/assets/images/totalEarningIcon.svg",
  totalprojectIcon: "/assets/images/totalprojectIcon.svg",
  transectionIcon: "/assets/images/transectionIcon.svg",
  profileImg: "/assets/images/profileImg.png",
  activeuser: "/assets/images/activeuser",
  pdfIcon: "/assets/images/pdfIcon.svg",
  editIcon: "/assets/images/editIcon.svg",
  StaffAvatar: "/assets/images/avatar01.png",
  earningGreenIcon: "/assets/images/earningGreenIcon.svg",
  cancleIcon: "/assets/images/cancleIcon.svg",
  completeProjectIcon: "/assets/images/completeProjectIcon.svg",
  totalClientBlueIcon: "/assets/images/totalClientBlueIcon.svg",
  reviewImg: "/assets/images/reviewImg.png",
  PfAvatar: "/assets/images/avatar02.png",
  closeGrayIcon: "/assets/images/closeGrayIcon.svg",
  crossIcon: "/assets/images/crossIcon.svg",
  homePaintingIcon: "/assets/images/homePaintingIcon.svg",
  homeCleningIcon: "/assets/images/homeCleningIcon.svg",
  salonIcon: "/assets/images/salonIcon.svg",
  acIcon: "/assets/images/acIcon.svg",
  carpenterIcon: "/assets/images/carpenterIcon.svg",
  plumbingIcon: "/assets/images/plumbingIcon.svg",
  pauseButton: "/assets/images/pauseButton.svg",
  uploadImg: "/assets/images/uploadImg.svg",
  lineGraph: "/assets/images/lineGraph.png",
  adsGraph: "/assets/images/adsGraph.png",
  Blog01: "/assets/images/blog01.png",
  columnGraph: "/assets/images/columnGraph.png",
  partnerProfileImg: "/assets/images/partnerProfileImg.png",
  cameraIcon: "/assets/images/cameraIcon.svg",
  blogDetailsImg: "/assets/images/blogDetailsImg.png",
  bookIcon: "/assets/images/bookIcon.svg",
  blogDetails: "/assets/images/blogDetails.png",
  relatedImg: "/assets/images/relatedImg.png",
  avatarChat: "/assets/images/avatarChat.png",
  sendIcon: "/assets/images/sendIcon.svg",
  muteIcon: "/assets/images/muteIcon.svg",
  attachIcon: "/assets/images/attachIcon.svg",
  emoji: "/assets/images/emoji.svg",
  avatar4: "/assets/images/avatar4.png",
  avatar3: "/assets/images/avatar3.png",
  adsDetailsBanner: "/assets/images/adsDetailsBanner.png",
  crossIconWhite: "/assets/images/crossIconWhite.svg",
  pendingIconYellow: "/assets/images/pendingIconYellow.svg",
  totalEarningBlue: "/assets/images/totalEarningBlue.svg",
  rightIcon: "/assets/images/rightIcon.svg",
  profileIcon: "/assets/images/profileIcon.svg",
  PincardIcon: "/assets/images/PincardIcon.svg",
  birthdayIcon: "/assets/images/birthdayIcon.svg",
  achievedIcon: "/assets/images/achievedIcon.svg",
  addressIcon: "/assets/images/addressIcon.svg",
  phnIcon: "/assets/images/phnIcon.svg",
  cityIcon: "/assets/images/cityIcon.svg",
  emailIcon: "/assets/images/emailIcon.svg",
  stateIcon: "/assets/images/stateIcon.svg",
  userIcon: "/assets/images/userIcon.svg",
  profileLineIcon: "/assets/images/profileLineIcon.svg",
  compusIcon: "/assets/images/compusIcon.svg",
  scanIcon: "/assets/images/scanIcon.svg",
  imageIcon: "/assets/images/imageIcon.svg",
  draphLineChart: "/assets/images/draphLineChart.png",
  noimg1: "/assets/images/noimage.jpg",
  noimg: "/assets/images/NoIMG.png",
  noimg2: "/assets/images/noImage2.png",
  nodatafound: "/assets/images/no-data-found.gif",
  userImage: "/assets/images/user2.png",
  userImage2: "/assets/images/user3.png",
  formIcon: "/assets/images/m1.svg",
  formIcon2: "/assets/images/m2.svg",
  formIcon3: "/assets/images/m3.svg",
  formIcon4: "/assets/images/m4.svg",
  formIcon5: "/assets/images/m5.svg",
  formIcon6: "/assets/images/m6.svg",
  formIcon7: "/assets/images/m7.svg",
  formIcon8: "/assets/images/m8.svg",
  formIcon9: "/assets/images/m9.svg",
  formIcon10: "/assets/images/m10.svg",
  formIcon11: "/assets/images/m11.svg",
  formIcon12: "/assets/images/m12.svg",
  formIcon14: "/assets/images/m14.svg",
  formIcon15: "/assets/images/m15.svg",
  formIcon18: "/assets/images/m18.svg",
  formIcon19: "/assets/images/m19.svg",
  formIcon20: "/assets/images/m20.svg",
  formClose: "/assets/images/close-white.svg",
  cross2: "/assets/images/cross2.svg",
  cross3: "/assets/images/cross3.svg",
  IconCross: "/assets/images/cross-wht.svg",
  Iconverified: "/assets/images/icon-verified.svg",
  pngIcon: "/assets/images/pngIcon.png",
  PDFIcon: "/assets/images/pdf-file.png",
  searchicnnew: "/assets/images/serchicon31.svg",
  globe: "/assets/images/globe.svg",
  proImage1: "/assets/images/p1.png",
  proImage2: "/assets/images/p2.png",
  proImage3: "/assets/images/p3.png",
  proImage4: "/assets/images/p4.png",
  proImage5: "/assets/images/p5.png",
  proImage6: "/assets/images/p6.png",
  arrw: "/assets/images/arrow.png",
  calendarIcon: "/assets/images/calendar_icon.svg",
  locationIcon: "/assets/images/location_icon.svg",
  verifiedIcon: "/assets/images/verified.svg",
  certificateIcon: "/assets/images/certificate.svg",
  onlinePaymentIcon: "/assets/images/online-payment.svg",
  successIcon: "/assets/images/success.png",
  faliureIcon: "/assets/images/unverified.png",
  closeIcon: "/assets/images/close-ico.svg",
  verifiedCircle: "/assets/images/verified-circle.svg",
  manageSuportCategory: "/assets/images/manage-suport-category.svg",
  manageSuportTicket: "/assets/images/manage-suport-ticket.svg",
  viewtraction: "/assets/images/viewtraction.svg",
  managetractionfees: "/assets/images/manage-transaction-fees.svg",
};
