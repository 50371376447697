export default {
  success: {},
  error: {
    enter_email: "This field is required",
    email_format: "Email format is not correct",
    enter_password: "This field is required",
    phone: "Enter phone",
    username: "Enter username",
    fullName: "Enter Full Name",
    firstName: "Enter First Name",
    common: "This field is required",
    ads_title: "Enter Ads Title",
    description: "Enter Description",
    category: "Enter Category",
    state: "Enter State",
    tags: "Enter Tags and press Enter button",
    start_date: "Enter Start Date",
    end_date: "Enter End Date",
  },
  errorPartner: {
    name: "Enter name",
    company_name: "Enter Company Name",
    category_id: "Enter Category",
    tier: "Enter tier",
    phone_number: "Enter Phone Number",
    email: "Enter Email",
    location: "Enter Location",
    start_date: "Enter Start Date",
    end_date: "Enter End Date",
    country: "Enter Country",
    rigion: "Enter Region",
    state: "Enter State",
    city: "Enter City",
  },
  errorStaff: {
    full_name: "Enter full name",
    email: "Enter email",
    phone: "Enter phone",
    location: "Enter location",
  },
  errorProvider: {
    fisrt_name: "Enter First Name",
    last_name: " Enter Last Name",
    email: "Enter Email",
    phone: "Enter Phone",
    // location: "Enter Location",
    dob: "Enter Dob",
    gender: "Enter Gender",
    about_service: "Enter about service",
    state: "Enter State",
    city: "Enter City",
    country: "Enter Country",
    home_address: "Enter Home Address",
    business_address: "Enter Business Address",
    nin_number: "Enter NIN Number",
    specialization: "Select Specialization ",
    qualification: "Enter Qualification",
    qualification_status: "Enter Qualification Status",
    // profile_image: "Enter profile_image",
    // cv: "Enter cv",
    // service_category: "Enter Service Category",
    joiningDate: "Please select a joining date",
  },
  errorProviderService: {
    service_title: "Enter service Title",
    service_description: "Enter service_description",
    service_category_id: "Enter service type",
    // specialization: "Enter specialization",
    city: "Enter city",
    state: "Enter state",
    address: "Enter address",
    business_phone: "Enter Business phone",
    service_images: "Enter service_images",
    description: "Enter description",
    business_address: "Enter Business address",
  },
  warn: {},
  info: {},
};
