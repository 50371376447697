import axiosInstance from "@/api/axiosInstance";
import { endpoints } from "@/api/endpoints";
import Seo from "@/components/Seo/Seo";
import { useAppDispatch } from "@/hooks/useAppDispatch";
import useNotiStack from "@/hooks/useNotistack";
import assest from "@/json/assest";
import validationText from "@/json/messages/validationText";
import Wrapper from "@/layout/wrapper/Wrapper";
import { setCookieClient } from "@/lib/functions/storage.lib";
import { checkWindow } from "@/lib/functions/_helpers.lib";
import { setLoginData } from "@/reduxtoolkit/slices/userSlice";
import styles from "@/styles/pages/login.module.scss";
import AuthButton from "@/ui/Buttons/AuthButton";
import CustomButton from "@/ui/Buttons/CustomButton";
import CustomInput from "@/ui/Inputs/CustomInput";

import {
  IconButton,
  InputAdornment,
  Typography,
  CircularProgress,
} from "@mui/material";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import { Box, styled } from "@mui/system";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { Password, Visibility, VisibilityOff } from "@mui/icons-material";
import { GetServerSideProps } from "next";
// import { GetprofileData } from "@/api/functions/user.api";
import { getProfileDetails } from "@/hooks/useProfileDetails";
import { Cookies } from "react-cookie";
import { parseCookies } from "nookies";
import { signInMutation } from "@/api/functions/user.api";
import { useMutation } from "react-query";

// STYLED COMPONENTS
const HeaderLogo = styled(Box)`
  .loginLogo {
    margin: 0 0 46px;
  }
`;
const LoginFrom = styled(Box)`
  .loginDetails {
    max-width: 503px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 56px;
    text-align: center;
    background: #ffffff;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    overflow: auto;
    height: 500px;
    @media (max-width: 991px) {
      padding: 10px;
    }
  }
  .loginDetails::-webkit-scrollbar {
    width: 5px;
  }
  .loginDetails::-webkit-scrollbar-thumb {
    background: #d90429;
    border-radius: 10px;
  }
  .loginForm {
    width: 100%;
  }
  .loginForm h2 {
    font-weight: 600;
    font-size: 32px;
    color: #131516;
    margin: 0 0 16px;
  }
  .loginForm p {
    font-weight: 400;
    font-size: 16px;
    color: #6c6c6c;
    margin: 0 0 30px;
  }
  .form_group {
    position: relative;
    margin: 0 0 16px;
  }
  .form_group label {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #121d32;
    margin: 0 0 6px;
  }
  .form_group sup {
    color: #eb2f2f;
  }
  .inputEyeButton {
    position: relative;
  }
  .eyeClose {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(0%, -50%);
    border: none;
    background: transparent;
    padding: 0;
    cursor: pointer;
  }
  .eyeOpen {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(0%, -50%);
    border: none;
    background: transparent;
    padding: 0;
    cursor: pointer;
  }
  .fromGroup {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 0 16px;
  }
  .fromCheckbox {
    display: flex;
    align-items: center;
  }
  .fromCheckbox label {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #6c6c6c;
    margin: 0 0 0 8px;
  }
  .fromGroup a {
    font-weight: 400;
    font-size: 14px;
    color: #2b2d42;
  }
  .cmnButton {
    p {
      font-weight: 600;
      font-size: 16px;
      text-align: center;
      color: #ffffff;
      margin: 0;
    }
  }
`;

const LogIn = styled(Box)`
  .loginwrapper {
    background-repeat: repeat;
    background-position: top left;
    height: 100vh;
    padding: 56px;
    @media (max-width: 767px) {
      padding: 20px;
    }
  }
`;

//=== TYPES ====
interface IFormInput {
  email: string;
  password: string;
}

// schema

const schema = yup.object().shape({
  email: yup
    .string()
    .email(validationText.error.email_format)
    .required(validationText.error.enter_email),
  password: yup
    .string()
    .required("Password  is required")
    .min(6,"Password must be at least 6 characters")
    .max(32,"Password must be at most 32 characters")
});

const Login = (profileData: any) => {
  const [check, setCheck] = useState(null);

  useEffect(() => {
    const cookies = parseCookies();
    const _value = JSON?.parse(cookies?.cred || null);
    const _value1 = JSON?.parse(cookies?.userDetails123 || null);

    console.log("🚀 ~ file: index.tsx:297 ~ useEffect ~ _value1:", _value1);

    if (_value) {
      setCheck(true);

      // setValues("email", _value1?.email);
      // setValues("password", _value1?.password);

      reset(_value1);
    } else {
      setCheck(false);
    }
  }, []);
  const cookies = parseCookies();

  /* Destructuring the useForm hook. */
  const {
    handleSubmit,
    control,
    register,
    setValue,
    reset,
    formState: { errors },
    formState,
  } = useForm<IFormInput>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const { toastSuccess, toastError } = useNotiStack();
  const dispatch = useAppDispatch();
  const router = useRouter();
  const { isSubmitting } = formState;
  // const onSubmit: SubmitHandler<IFormInput> = (data) => {
  //   console.log(data,"sadda");

  //   dispatch(setLoginData(data));
  //   toastSuccess("Login success");
  //   reset();
  // };
  const cookie = new Cookies();

  // const { data, mutate:profileGet} = getProfileDetails();
  // console.log(data,"beeeeeee");

  const { mutate, isLoading } = useMutation(signInMutation);

  const onSubmit: SubmitHandler<IFormInput> = async (
    dataDetails: any
  ) => {
    mutate(dataDetails, {
      onSuccess: (data: any) => {
        if (data.status === 200) {
          //let date = new Date();
          reset();
          dispatch(setLoginData(data?.data));
          //profileGet();
          const input = {
            email: data?.data.email,
            Password: data?.data.password,
          };
          setCookieClient("userDetails123", JSON.stringify(dataDetails));
          setCookieClient("userDetail", JSON.stringify(data?.data));
          //setCookieClient("userDetails", JSON.stringify(data?.data));

          setCookieClient("token", data.token);

          setCookieClient("cred", check ? JSON.stringify(data?.data) : "");
          toastSuccess(`${data.message}`);
          // if (checkWindow()) {
          // }
          window.location.href = "/";
        } else {
          toastError(`${data.message}`);
        }
      },
    });

    // setloading(true);
    // const { data } = await axiosInstance.post(
    //   endpoints?.auth?.login,
    //   dataDetails
    // );
    // console.log(data?.data, "sdad123");
    // // setloading(false);

    // if (data?.status === 200) {
    //   reset();
    //   dispatch(setLoginData(data?.data));
    //   //profileGet();
    //   const input = {
    //     email: data?.data.email,
    //     Password: data?.data.password,
    //   };
    //   setCookieClient("userDetails123", JSON.stringify(dataDetails));
    //   setCookieClient("userDetail", JSON.stringify(data?.data));
    //   //setCookieClient("userDetails", JSON.stringify(data?.data));

    //   setCookieClient("token", data.token);

    //   setCookieClient("cred", check ? JSON.stringify(data?.data) : "");

    //   if (checkWindow()) {
    //   }
    //   window.location.href = "/";
    // }

    // setCookieClient("user", JSON.stringify(data.data));
    // if (checkWindow()) {
    //   window.location.href = "/";
    // }
    //toastSuccess(data?.message);
    // router.push("/");
    // } else {
    // toastError(data?.mesage);
    // }

    // console.log(data, "asda");
  };

  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event: { preventDefault: () => void }) => {
    event.preventDefault();
  };

  const handlePasswordChange =
    (prop: any) => (event: { target: { value: any } }) => {
      setValues({ ...values, [prop]: event.target.value });
    };

  return (
    <>
      <Seo title="Wokka" image="" />
      <Wrapper hasHeader={false} hasFooter={false}>
        {/* <Container maxWidth="sm">
          <Paper className={styles.loginBox}>
            <h1>Login</h1>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Controller
                control={control}
                {...register("email")}
                render={({ field: { onChange, value } }) => (
                  <CustomInput
                    label="Enter email*"
                    type="text"
                    value={value}
                    onChange={onChange}
                    placeholder="Enter username"
                    error={Boolean(errors?.email)}
                    helperText={errors?.email?.message}
                  />
                )}
              />

              <Controller
                control={control}
                {...register("password")}
                render={({ field: { onChange, value } }) => (
                  <CustomInput
                    label="Enter password*"
                    value={value}
                    type="password"
                    onChange={onChange}
                    placeholder="Enter password"
                    error={Boolean(errors?.password)}
                    helperText={errors?.password?.message}
                  />
                )}
              />

              <CustomButton type="submit" disabled={false}>
                <span>Submit</span>
              </CustomButton>
            </form>
          </Paper>
        </Container> */}
        <LogIn>
          <Box
            className="loginwrapper"
            style={{ backgroundImage: "url(/assets/images/patternBG.png)" }}
          >
            <HeaderLogo>
              <Box className="loginLogo">
                <Link href="/login">
                  <Image
                    src={assest.loginLogo}
                    width={340}
                    height={100}
                    alt=""
                  />{" "}
                </Link>
              </Box>
            </HeaderLogo>

            <LoginFrom>
              {/* <form onSubmit={handleSubmit(onSubmit)}> */}
              <Box className="loginDetails">
                <Box className="loginForm">
                  <h2>Login</h2>
                  <p>Welcome back! Please Login</p>
                  <Box className="form_group">
                    <label>
                      Email <sup>*</sup>
                    </label>
                    <input
                      type="text"
                      placeholder="example@email.com"
                      {...register("email")}
                    />
                    {errors.email && (
                      <p
                        style={{
                          color: "red",
                          textAlign: "left",
                          fontSize: "15px",
                        }}
                      >
                        {errors.email.message}
                      </p>
                    )}
                  </Box>

                  <Box className="form_group">
                    <label>
                      Password <sup>*</sup>
                    </label>
                    <Box className="inputEyeButton">
                      <input
                        type={values.showPassword ? "password" : "text"}
                        placeholder="Enter password"
                        {...register("password")}
                      />
                       {errors.password && <p style={{color:'red',textAlign:"left",fontSize:"15px"}}>{errors.password.message}</p>}
                      {/* {errors.password && <p style={{color:'red',textAlign:"left",fontSize:"15px"}}>{errors.password.message}</p>}
                        <button className="eyeClose" onClick={handleClickShowPassword}
                          bonMouseDown={handleMouseDownPassword}>
                          <Image
                            src={assest.eyeClose}
                            width={22}
                            height={22}
                            alt=""
                          />
                        </button>
                        <button className="eyeOpen" onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}>
                          <Image
                            src={assest.eyeOpen}
                            width={22}
                            height={22}
                            alt=""
                          />
                        </button> */}
                      <InputAdornment className="eyeClose" position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {values.showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    </Box>
                  </Box>

                  <Box className="fromGroup">
                    <Box className="fromCheckbox">
                      <input
                        type="checkbox"
                        checked={check}
                        onChange={() => setCheck(!check)}
                      />
                      <label>Remember Me</label>
                    </Box>
                    <Link href="/find-account">Forgot Password?</Link>
                  </Box>

                  <Box className="cmnButton">
                    {/* <Link href="/find-account"> */}
                    {/* <AuthButton type="submit" onClick={handleSubmit(onSubmit)} disabled={isSubmitting}>
                    {isSubmitting && <CircularProgress size="1.5rem" style={{ marginRight: '1rem' }} />}
                      <p>Login</p>
                    </AuthButton> */}
                    {/* </Link> */}
                    <AuthButton
                      type="submit"
                      onClick={handleSubmit(onSubmit)}
                      disabled={isSubmitting}
                    >
                      {isSubmitting && (
                        <CircularProgress
                          size="1rem"
                          style={{ marginRight: "1rem", color: "red" }}
                        />
                      )}
                      <p>Login</p>
                    </AuthButton>
                  </Box>
                </Box>
              </Box>
              {/* </form> */}
            </LoginFrom>
          </Box>
        </LogIn>
      </Wrapper>
    </>
  );
};

export default Login;
