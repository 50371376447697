import dynamic from "next/dynamic";
import React, { Suspense, useEffect, useState } from "react";
import NextProgress from "next-progress";
import HeaderSkeleton from "@/ui/Skeletons/HeaderSkeleton";
import useOnlineStatus from "@/hooks/useDetectOnline";
import { Backdrop, CircularProgress } from "@mui/material";
import assest from "@/json/assest";
import Image from "next/image";


const Header = dynamic(() => import("../Header/Header"), { ssr: true });
const Footer = dynamic(() => import("../Footer/Footer"), { ssr: true });

interface wrapperProps {
  children: JSX.Element | JSX.Element[];
  hasHeader?: boolean;
  hasFooter?: boolean;
}

const Wrapper = (props: wrapperProps) => {
  const { children, hasHeader, hasFooter } = props;
  //console.log(hasHeader,"hasHeader");

  useOnlineStatus();

  const [loader, setLoader] = useState(true);

  // useOnlineStatus();

  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 500);
  }, []);

  if (loader) {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open
      >
        {/* <CircularProgress color="error" /> */}
        <Image src={assest.loginLogo} width={379} height={100} alt="" />{" "}
      </Backdrop>
    );
  }

  return (
    <>
      {/* <NextProgress height={8} color="green" /> */}
      {hasHeader && <Header />}
      {children}

      {hasFooter && <Footer />}
    </>
  );
};

export default Wrapper;
